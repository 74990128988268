import {
  Directive,
  Input,
  ElementRef,
  inject,
  DestroyRef, OnInit,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '../services/translate.service';
import LanguageService from '../services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserRole } from '@core-models/user-role.enum';

@Directive({
  selector: '[vlcCalculateUserRole]',
  standalone: true,
})
export class CalculateUserRoleDirective implements OnInit {
  @Input('vlcCalculateUserRole') permissionScope: UserRole = UserRole.User;
  private translateService = inject(TranslateService);
  private languageService = inject(LanguageService);
  private destroyRef = inject(DestroyRef);

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.switch();
    this.languageService.onLanguageChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.switch();
      });
  }

  private roleTranslationMap: { [key in UserRole]: string } = {
    [UserRole.User]: 'vlc:Admin:Management.Settings.User',
    [UserRole.WidgetEditor]: 'vlc:Admin:Management.Settings.Widget.Editor',
    [UserRole.TenantAdmin]: 'vlc:Admin:Management.Settings.Tenant.Admin',
    [UserRole.GroupAdmin]: 'vlc:Admin:Management.Settings.Group.Admin',
    [UserRole.SuperAdmin]: 'vlc:Admin:Management.Settings.Super.Admin',
  };

  switch() {
    const userRoleKey = this.roleTranslationMap[this.permissionScope] || 'Unknown';
    const userRole = this.translateService.translateInstant(userRoleKey);
    this.renderer.setProperty(this.el.nativeElement, 'innerText', userRole);
  }
}
