import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  retry,
  timer,
} from 'rxjs';
import { CurrentUser } from '@shared-models/current-user.model';
import LanguageService from './language.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  baseUrl: string = environment.BaseUri;
  userEndpoint = `${this.baseUrl}/api/user/me`;
  tenantLanguagesEndpoint = `${this.baseUrl}/api/language/GetTenantLanguages`;
  private _currentUserSubject = new BehaviorSubject<CurrentUser>(
    new CurrentUser()
  );
  public currentUser$: Observable<CurrentUser> =
    this._currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
    private languageService: LanguageService
  ) {
    this.fetchAndSetUser();
  }

  get currentUser() {
    return this._currentUserSubject.value;
  }

  me() {
    return this.http.get(this.userEndpoint);
  }

  fetchAndSetUser() {
    console.debug('Fetching user data..');

    this.me()
      .pipe(
        retry({
          count: 3,
          delay: (retryCount) => {
            if (retryCount >= 3) {
              throw new Error('User data is null or undefined.');
            }
            console.debug(
              `Retrying to fetch user data after 5 seconds... Retry count: ${
                retryCount + 1
              }`
            );
            return timer(5000);
          },
        })
      )
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (response: any) => {
          if (response) {
            const user = response.ObjectResult as CurrentUser;
            this.handleUser(user);
          } else {
            console.error('Unexpected response format.');
          }
        },
        error: (error) => {
          console.error('Exceeded maximum retry attempts.', error);
        },
      });
  }

  async handleUser(user: CurrentUser) {
    this._currentUserSubject.next(user);
    this.handleUserLanguage(user);
  }

  async handleUserLanguage(user: CurrentUser) {
    if (!this.languageService.getLanguageFromLocalStorage()) {
      if (user.Language.ISOCode) {
        this.languageService.setCurrentLanguage(user.Language.ISOCode);
      } else {
        const tenantLanguage = await this.getTenantLanguage();
        if (tenantLanguage) {
          this.languageService.setCurrentLanguage(tenantLanguage);
        }
      }
    }
  }

  async getTenantLanguage(): Promise<string | undefined> {
    const result = await firstValueFrom(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.http.get<any>(this.tenantLanguagesEndpoint)
    );

    if (result.ObjectResult?.TenantLanguageId) {
      const tenantLanguageId = result.ObjectResult?.TenantLanguageId;
      const tenantLanguage = result.Results?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (l: any) => l.Id == tenantLanguageId
      );
      if (tenantLanguage) {
        return tenantLanguage.ISOCode;
      }
    }

    return undefined;
  }
}
