import { UserRole } from '@core-models/user-role.enum';
import { TenantSettingsModel } from '@modules/settings/models/tenants-settings.model';
import { UserLanguage } from './user-language.model';

export class CurrentUser {
  Id: string = '';
  Name: string = '';
  Surname?: string;
  MailAddress?: string;
  JobTitle?: string;
  DisplayName?: string;
  Department?: string;
  CompanyName?: string;
  Country?: string;
  City?: string;
  State?: string;
  PostalCode?: string;
  StreetAddress?: string;
  IsActive?: boolean;
  TelephoneNumber?: string;
  Mobile?: string;
  FaxNumber?: string;
  ManagerId?: string;
  JoinDate?: string;
  PermissionScope: UserRole = UserRole.User;
  ProfileImageUri?: string;
  TenantId?: string;
  TenantName?: string;
  FullName: string = '';
  Language: UserLanguage = new UserLanguage();
  AuthId: string = '';
  Office: string = '';
  TenantSettings!: TenantSettingsModel;
}
